import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { LocalizedContentBase, WithId } from "@maxxton/cms-api";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { getContentTypeSelector, getDynamicFieldCodeList } from "../../../components/utils";
import { getLinkFromLinkingSpec, linkingSpecDefaultValues } from "../../../utils/linking.util";

import { Location } from "./Location";
import { LocationWidgetSpec } from "../../";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { SharedWidgetParams } from "../../widget";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { tabLinkAutocomplete } from "../../../inputSpecs";

export interface WidgetOptions extends LocalizedTitleOptions, WithId {
    showResortName: boolean;
    showResortLocation: boolean;
    showResortLocationIcon: boolean;
    showAddressInformation: boolean;
    styleIds: string[];
    styleResortName: boolean;
    resortNameColor: string;
    resortNameFontSize: string;
    styleResortLocation: boolean;
    resortLocationFontSize: string;
    locationIconColor: string;
    displayOnlyIcon: false;
    tabLink?: string;
    contentTypeSelector?: boolean;
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    toggleButton: boolean;
    ElementToToggle: string;
    linking: LinkingSpecOptions;
    resortLinkList?: string;
    linkResortName?: boolean;
    linkLocationName?: boolean;
    locationLinkList?: string;
    dynamicFieldCodes?: NumberMultiSelectOption[];
    localizedOptionsResort?: LocalizedOptionsCode[];
    localizedOptionsLocation?: LocalizedOptionsCode[];
    showAddressInformationInline: boolean;
    useAsDynamicBookingUrlLink: boolean;
    showPlanYourRouteButton: boolean;
}

export interface WidgetOptionsFormParams {
    widgetOptionsFormId: string;
}
export interface LocalizedOptionsCode extends LocalizedContentBase {
    dynamicFieldCodes: string;
}

const TARGETS = ["results-panel-location"];

/* jscpd:ignore-start */
// eslint-disable-next-line max-lines-per-function
const widgetOptionsForm = ({ widgetOptionsFormId }: WidgetOptionsFormParams): FormSpec<WidgetOptions> => ({
    id: widgetOptionsFormId,
    name: getI18nLocaleObject(namespaceList.widgetResultsLocation, "resultPanelLocation"),
    pluralName: getI18nLocaleObject(namespaceList.widgetResultsLocation, "resultPanelLocations"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            ...getContentTypeSelector(),
                            {
                                variable: "showResortName",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "useResortName"),
                                type: "checkbox",
                                groupName: "resortInfoGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetResultsLocation, "showResortNameGroupTitle"),
                            },
                            {
                                variable: "linkResortName",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "linkResortName"),
                                type: "checkbox",
                                groupName: "resortInfoGroup",
                                visible: (options: WidgetOptions): boolean => options.showResortName,
                            },
                            localized({
                                variable: "localizedOptionsResort",
                                visible: (options: WidgetOptions): boolean => !!(options.showResortName && options.linkResortName),
                                tabContent: [
                                    {
                                        variable: "dynamicFieldCodes",
                                        type: "autocomplete",
                                        isClearable: false,
                                        label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "resortLinkList"),
                                        options: () => getDynamicFieldCodeList(MxtsApi),
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "defaultPlaceholder"),
                                    },
                                ],
                                groupName: "resortInfoGroup",
                            }),
                            {
                                variable: "showResortLocation",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "useResortLocation"),
                                type: "checkbox",
                                groupName: "resortInfoGroup",
                            },
                            {
                                variable: "linkLocationName",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "linkLocationName"),
                                type: "checkbox",
                                groupName: "resortInfoGroup",
                                visible: (options: WidgetOptions): boolean => options.showResortLocation,
                            },
                            localized({
                                variable: "localizedOptionsLocation",
                                visible: (options: WidgetOptions): boolean => !!(options.showResortLocation && options.linkLocationName),
                                tabContent: [
                                    {
                                        variable: "dynamicFieldCodes",
                                        type: "autocomplete",
                                        isClearable: false,
                                        label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "locationLinkList"),
                                        options: () => getDynamicFieldCodeList(MxtsApi),
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "defaultPlaceholder"),
                                    },
                                ],
                                groupName: "resortInfoGroup",
                            }),
                            {
                                variable: "showAddressInformation",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "showAddressInformation"),
                                type: "checkbox",
                                groupName: "resortInfoGroup",
                            },
                            {
                                variable: "showAddressInformationInline",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "showAddressInformationInline"),
                                type: "checkbox",
                                visible: (options: WidgetOptions): boolean => options.showAddressInformation,
                                groupName: "resortInfoGroup",
                            },
                            {
                                variable: "toggleButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleButton"),
                                type: "checkbox",
                            },
                            {
                                variable: "ElementToToggle",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "ElementToToggle"),
                                type: "text",
                                visible: (options: WidgetOptions): boolean => options.toggleButton,
                            },
                            {
                                variable: "showPlanYourRouteButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "showPlanYourRouteButton"),
                                type: "checkbox",
                                visible: (options: WidgetOptions): boolean => options.showAddressInformation,
                                groupName: "resortInfoGroup",
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [tabLinkAutocomplete("tabLink")],
                        [
                            {
                                variable: "useAsDynamicBookingUrlLink",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsDynamicBookUrlLink"),
                                type: "checkbox",
                            },
                            linkingSpec<WidgetOptions, keyof WidgetOptions>(
                                {
                                    variable: "linking",
                                    visible: (options: WidgetOptions) => !options.linkResortName && !options.linkLocationName,
                                },
                                true
                            ),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "styleResortName",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "styleResortName"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.showResortName,
                            },
                            {
                                variable: "resortNameFontSize",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "resortNameFontSize"),
                                type: "select",
                                default: "default",
                                visible: (options: WidgetOptions) => options.showResortName === true && options.styleResortName,
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "resortNameColor",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "resortNameColor"),
                                type: "dual-color",
                                default: "font-default",
                                visible: (options: WidgetOptions) => options.showResortName === true && options.styleResortName,
                            },
                            {
                                variable: "styleResortLocation",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "styleResortLocation"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.showResortLocation,
                            },
                            {
                                variable: "resortLocationFontSize",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "resortLocationFontSize"),
                                type: "select",
                                default: "default-font-size",
                                visible: (options: WidgetOptions) => options.showResortLocation === true && options.styleResortLocation,
                                optionList: [
                                    {
                                        value: "default-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "showResortLocationIcon",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "showResortLocationIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showResortLocation === true && options.styleResortLocation,
                            },
                            {
                                variable: "locationIconColor",
                                label: getI18nLocaleObject(namespaceList.widgetResultsLocation, "locationIconColor"),
                                type: "dual-color",
                                default: "font-default",
                                visible: (options: WidgetOptions) => options.styleResortLocation && options.showResortLocationIcon,
                            },
                            {
                                variable: "displayOnlyIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayOnlyIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showResortLocation === true && options.styleResortLocation && options.showResortLocationIcon,
                            },
                            ...titleStylingOptions<WidgetOptions>(),
                        ],
                    ],
                },
            ],
        },
    ],
});
/* jscpd:ignore-end */

export const locationWidget = ({ widgetId, widgetType, widgetOptionsFormId }: SharedWidgetParams): LocationWidgetSpec<WidgetOptions> => ({
    id: widgetId,
    type: widgetType,
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.widgetResultsLocation, "resultsPanelLocationWidget"),
    description: getI18nLocaleObject(namespaceList.widgetResultsLocation, "resultsPanelLocationWidgetDescription"),
    optionsForm: widgetOptionsForm({ widgetOptionsFormId }),
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        styleIds: [],
        showResortName: false,
        styleResortName: false,
        resortNameFontSize: "default",
        resortNameColor: "font-default",
        showResortLocation: false,
        styleResortLocation: false,
        showResortLocationIcon: false,
        showAddressInformation: false,
        resortLocationFontSize: "default-font-size",
        locationIconColor: "font-default",
        displayOnlyIcon: false,
        toggleButton: false,
        ElementToToggle: "",
        enableWidgetTitle: false,
        localizedWidgetTitle: [],
        styleWidgetTitle: false,
        useTitleHeadings: "p",
        linking: linkingSpecDefaultValues,
        showAddressInformationInline: false,
        useAsDynamicBookingUrlLink: false,
        showPlanYourRouteButton: false,
    }),
    async render(widget, context, sitemapWidgetOptions, dynamicContainerOptions) {
        const link = await getLinkFromLinkingSpec({ linkingSpecOptions: widget.options.linking, context });
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        return <Location dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} className={styleClasses} link={link} />;
    },
    container: false,
});
