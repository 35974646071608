import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { AccommodationType, Resort, Unit } from "../../mxts";
import { Address, ApiCallOptions, DynamicFieldInfo, MxtsApiWrapper, PagedResult } from "@maxxton/cms-mxts-api";
import { ConfiguredLink, getUrlWithAnchor } from "../../../utils/linking.util";
import { Content, getContent, getDynamicFieldCodeValue } from "../../../utils/content.util";
import { DispatchOpenLinkedTabOptions, getDynamicBookLink } from "../../resultsPanel/resultsPanelUtil";
import { DynamicFieldLink, withDynamicField } from "../../../utils/withDynamicField";
import { LocalizedOptionsCode, WidgetOptions } from "./";
import { UrlLinkParams, UrlParamsUtil } from "../../../utils/urlparam.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { CustomLink } from "../../../components/CustomLink";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../../dynamic/dynamicWidget.types";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { ReservationContainerCRPProps } from "../../dynamic/reservation/container/reservationContainer.types";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { fetchResortsByResortIds } from "../../../utils/resort.util";
import { getEnv } from "../../../utils/env.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../components/utils";

interface LocationWidgetStoreProps {
    myEnvState: MyEnvState;
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
}

interface LocationWidgetBaseProps extends DynamicWidgetBaseProps<WidgetOptions>, ReservationContainerCRPProps {
    resort?: Resort;
    accommodationType?: AccommodationType;
    unit?: Unit;
    dispatchOpenLinkedTabAction?: (options: DispatchOpenLinkedTabOptions) => void;
    link: ConfiguredLink;
    dynamicFieldLink?: DynamicFieldLink;
    getDynamicFieldLink?: (content: Content, usedForLinking?: boolean) => Promise<void>;
}
interface LocationWidgetProps extends LocationWidgetBaseProps, LocationWidgetStoreProps {}

type UnitOrAccommodationType = AccommodationType | Unit | undefined;

type LocationWidgetPropsWithContent = LocationWidgetProps & { content: Exclude<Content, Resort[]> };

const isResortType = (content: Exclude<Content, Resort[]>) => (content as Exclude<Content, Activity | Resort[]>)?.resortId && !(content as UnitOrAccommodationType)?.resourceId;

const getResortName = ({ content, accommodationType, unit, resort }: LocationWidgetPropsWithContent) => {
    const resortType = isResortType(content);
    const resortName = (content as UnitOrAccommodationType)?.resortName || (resortType && (content as Resort)?.name) || "";

    if (!resortName) {
        if (resort?.name && resort.resortId === (content as UnitOrAccommodationType)?.resortId) {
            return resort.name;
        }

        if (accommodationType?.resortName && accommodationType.resortId === (content as UnitOrAccommodationType)?.resortId) {
            return accommodationType.resortName;
        }

        if (unit?.resortName && unit.resortId === (content as UnitOrAccommodationType)?.resortId) {
            return unit.resortName;
        }
    }

    return resortName;
};

const getResortLocation = ({ content, accommodationType, unit, resort }: LocationWidgetPropsWithContent) => {
    const resortLocation = (content as AccommodationType)?.city || "";

    if (!resortLocation) {
        if (resort?.city && resort.resortId === (content as UnitOrAccommodationType)?.resortId) {
            return resort.city;
        }

        if (accommodationType?.city && accommodationType.resortId === (content as UnitOrAccommodationType)?.resortId) {
            return accommodationType.city;
        }

        if (unit?.city && unit.resortId === (content as UnitOrAccommodationType)?.resortId) {
            return unit.city;
        }
    }

    return resortLocation;
};

const getUnavailableAdressInformation = async (mxtsApi: MxtsApiWrapper, options: WidgetOptions, contentAddress: Address | undefined, resortId: number, env: ApiCallOptions) => {
    if (!contentAddress || !contentAddress.countryId) {
        // Take fallback address from resort. Note:- Address 1, City, Zip code are mendatory for accommodation but countryId is not.
        const currentResorts: Array<Resort | Resort> = await fetchResortsByResortIds({ resortIds: [resortId], env, includeAddress: options.showAddressInformation }, mxtsApi);
        if ((currentResorts[0] as Resort).address && !contentAddress) {
            // If nothing is available in accommodation address, set fallback address from resort.
            contentAddress = (currentResorts[0] as Resort).address;
        }
        if ((currentResorts[0] as Resort).address?.countryId && !contentAddress?.countryId) {
            // As country is not mendatory that's why chekcing if no country present in accommodation then set fallback country from resort.
            const countryId = (currentResorts[0] as Resort).address?.countryId;
            contentAddress = contentAddress && { ...contentAddress, countryId };
        }
    }
    const countriesGroupById = contentAddress?.countryId && (await mxtsApi.countries(env, { countryIds: [contentAddress.countryId] }).then((response) => response.content));
    const countryName = countriesGroupById && countriesGroupById[0].longName;
    return {
        contentAddress,
        countryName,
    };
};

// eslint-disable-next-line max-lines-per-function
const LocationBase = (props: LocationWidgetProps) => {
    const {
        className,
        options,
        isMyEnvWidget,
        dispatchOpenLinkedTabAction,
        context: { currentLocale, site },
        myEnvState,
        esReservationResult,
        dynamicFilter,
        link,
        accommodationType,
        unit,
        getDynamicFieldLink,
        dynamicFieldLink,
    } = props;
    const [content, setContent] = React.useState<Exclude<Content, Resort[]>>();
    const resortName = getResortName({ ...props, content });
    const resortLocation = getResortLocation({ ...props, content });
    const selectedReservation = isMyEnvWidget ? myEnvState.selectedReservation : undefined;
    const [resortLinkValue, setResortLinkValue] = React.useState<string | null | undefined>();
    const [locationLinkValue, setlocationLinkValue] = React.useState<string | null>();
    const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
    const [linkUrl, setLinkUrl] = React.useState<string | null>();
    const hideWidget = setOpacityOnHide(options);
    const loadContent = React.useCallback(async () => {
        const content = (await getContent({ ...props, includeAddress: options.showResortLocation || options.showAddressInformation })) as Exclude<Content, Resort[]>;
        const resortName = getResortName({ ...props, content });
        const resortLocation = getResortLocation({ ...props, content });
        const requiredResortName = options.showResortName && !resortName;
        const requiredResortLocation = options.showResortLocation && !resortLocation;
        let locationLinkValue;
        let resortLinkValue;
        if (options?.linking?.useDynamicFieldAsLink && getDynamicFieldLink && !dynamicFieldLink) {
            getDynamicFieldLink(content, true);
        }
        if ((options.localizedOptionsLocation?.[0]?.dynamicFieldCodes && options.linkResortName) || (options.localizedOptionsResort?.[0]?.dynamicFieldCodes && options.linkLocationName)) {
            const localizedOptionsLocation = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedOptionsLocation || [] }) || {};
            const locationLink = (localizedOptionsLocation as LocalizedOptionsCode).dynamicFieldCodes;
            const localizedOptionsResort = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedOptionsResort || [] }) || {};
            const resortLink = (localizedOptionsResort as LocalizedOptionsCode).dynamicFieldCodes;
            const [env, content] = await Promise.all([getEnv(props), getContent({ ...props, skipContentTypeSelectorCheck: true }) as Promise<Exclude<Content, Resort[]>>]);
            const widgetOptionsDynamicFieldCodesPaths: Array<keyof WidgetOptions> = ["dynamicFieldCodes", "localizedOptionsLocation", "localizedOptionsResort"];
            if (locationLink || resortLink) {
                [locationLinkValue, resortLinkValue] = await Promise.all([
                    options.linkLocationName
                        ? getDynamicFieldCodeValue({ content, env, dynamicFieldCode: locationLink, apiContext: props.context, widgetOptionsId: options._id, widgetOptionsDynamicFieldCodesPaths }).then(
                              (response: DynamicFieldInfo) => response?.value
                          )
                        : null,
                    options.linkResortName
                        ? getDynamicFieldCodeValue({ content, dynamicFieldCode: resortLink, env, apiContext: props.context, widgetOptionsId: options._id, widgetOptionsDynamicFieldCodesPaths }).then(
                              (response: DynamicFieldInfo) => response?.value
                          )
                        : null,
                ]);
                setResortLinkValue(resortLinkValue);
                setlocationLinkValue(locationLinkValue);
            }
        }
        if (content && (requiredResortLocation || requiredResortName || options.showAddressInformation)) {
            const resortId = (content as Exclude<Content, Activity | Resort[] | undefined>).resortId;
            const contentAddress: Address | undefined = (content as AccommodationType).address || (content as Unit).address || (content as Resort).address;
            const env = await getEnv(props);
            let updatedContent = { ...content };

            if (requiredResortName) {
                const resort: Resort[] = await props.context.mxtsApi.resorts(env, { size: 1, resortIds: [resortId] }).then((res: PagedResult<Resort>) => res.content);
                const { name } = resort[0] || {};
                if (isResortType(updatedContent)) {
                    updatedContent = { ...updatedContent, name };
                } else {
                    updatedContent = { ...updatedContent, resortName: name };
                }
            }
            if (requiredResortLocation) {
                if (contentAddress) {
                    const { city } = contentAddress || {};
                    updatedContent = { ...updatedContent, city };
                }
            }
            if (options.showAddressInformation) {
                const { contentAddress: updatedContentAddress, countryName } = await getUnavailableAdressInformation(props.context.mxtsApi, options, contentAddress, resortId, env);
                updatedContent = { ...updatedContent, address: updatedContentAddress && { ...updatedContentAddress }, countryName: countryName || "" };
            }
            setContent({ ...updatedContent });
        } else {
            setContent(content);
        }
        // not providing any useCallback deps as loadContent is called only once on component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReservation]);

    React.useEffect(() => {
        loadContent();
        const params: UrlLinkParams = dynamicFilter ? UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter) : {};
        const resourceId =
            Array.isArray(dynamicFilter.resourceid) && dynamicFilter.resourceid.length === 1
                ? dynamicFilter.resourceid[0]
                : !Array.isArray(dynamicFilter.resourceid)
                ? dynamicFilter.resourceid
                : undefined;
        const addResourceIdParams = resourceId || accommodationType?.resourceId || unit?.resourceId;
        const addUnitIdParams = dynamicFilter?.unitid || (content as Unit)?.unitId;
        const dynamicBookUrl = getDynamicBookLink(params, link, addResourceIdParams, addUnitIdParams);
        const linkUrl = !options.linkResortName && !options.linkLocationName ? (options.useAsDynamicBookingUrlLink && dynamicBookUrl) || getUrlWithAnchor(link) : null;
        setLinkUrl(linkUrl);
    }, [loadContent]);

    const handleDispatchOpenLinkedTabAction = () => {
        if (typeof dispatchOpenLinkedTabAction === "function") {
            dispatchOpenLinkedTabAction(options);
        }
    };

    const myEnvLink: string | undefined = esReservationResult && UrlParamsUtil.getMyEnvLink(dynamicFilter, esReservationResult, link);

    const titleOptions = {
        localizedTitle: localizedWidgetTitle,
        enableWidgetTitle: options.enableWidgetTitle,
        useTitleHeadings: options.useTitleHeadings,
        styleWidgetTitle: options.styleWidgetTitle,
        className: classNames("widget-heading", `${options.fontColor?.includes("theme") ? `color-${options.fontColor}` : ""}`),
        style: options.fontColor?.includes("rgba") ? options.fontColor : "",
    };

    const renderAddress = (content: Exclude<Content, Resort[]>) => (
        <div className={classNames("address-information", { "address-inline": options.showAddressInformationInline })}>
            <div className={classNames("address-information__address")}>
                <span className={classNames("address-information__streetname")}>{(content as Resort)?.address?.address1}</span>
                <span className={classNames("address-information__housenumber")}>{(content as Resort)?.address?.houseNumber || ""}</span>
            </div>
            <span className={classNames("address-information__zipCode")}>{(content as Resort)?.address?.zipCode}</span>
            <span className={classNames("address-information__city")}>{(content as Resort)?.address?.city}</span>
            <span className={classNames("address-information__countryName")}>{(content as Resort)?.countryName}</span>
        </div>
    );

    /* jscpd:ignore-start */
    const renderLocationData: JSX.Element[] = [];

    const updatedLink = options?.linking?.useDynamicFieldAsLink ? dynamicFieldLink?.url : linkUrl;
    if (options.showResortName && options.showResortLocation && content) {
        renderLocationData.push(
            <div className={classNames("accommodation-location", className, { "cursor-pointer": !!options.tabLink })} onClick={handleDispatchOpenLinkedTabAction}>
                <LocalizedTitleAndLabel {...titleOptions} />
                {updatedLink ? (
                    <React.Fragment>
                        {resortLinkContent(options, link, resortName, myEnvLink, updatedLink)}
                        {locationLinkContent(options, link, resortLocation, myEnvLink, false, updatedLink)}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {resortLinkValue ? (
                            <SmartLink href={resortLinkValue} target="_blank" className="resort-link">
                                {resortLinkContent(options, link, resortName, myEnvLink)}
                            </SmartLink>
                        ) : (
                            resortLinkContent(options, link, resortName, myEnvLink)
                        )}
                        {locationLinkValue ? (
                            <SmartLink href={locationLinkValue} target="_blank" className="resort-location-link">
                                {locationLinkContent(options, link, resortLocation, myEnvLink, false)}
                            </SmartLink>
                        ) : (
                            locationLinkContent(options, link, resortLocation, myEnvLink, false)
                        )}
                    </React.Fragment>
                )}
                {options.showAddressInformationInline && renderAddress(content)}
                {options.showPlanYourRouteButton && options.showAddressInformationInline && renderRouteUrl(content)}
            </div>
        );
    } else if (options.showResortName && !options.showResortLocation && content) {
        renderLocationData.push(
            <div className={className} onClick={handleDispatchOpenLinkedTabAction}>
                <LocalizedTitleAndLabel {...titleOptions} />

                {updatedLink ? (
                    <React.Fragment>{resortLinkContent(options, link, resortName, myEnvLink, updatedLink)}</React.Fragment>
                ) : (
                    <React.Fragment>
                        {resortLinkValue ? (
                            <SmartLink href={resortLinkValue} target="_blank" className="resort-link">
                                {resortLinkContent(options, link, resortName, myEnvLink)}
                            </SmartLink>
                        ) : (
                            resortLinkContent(options, link, resortName, myEnvLink)
                        )}
                    </React.Fragment>
                )}
                {options.showPlanYourRouteButton && !options.showAddressInformation && !options.showAddressInformationInline && renderRouteUrl(content)}
            </div>
        );
    } else if (options.showResortLocation && content) {
        renderLocationData.push(
            <div className={className} onClick={handleDispatchOpenLinkedTabAction}>
                {updatedLink ? (
                    <React.Fragment>{locationLinkContent(options, link, resortLocation, myEnvLink, false, updatedLink)}</React.Fragment>
                ) : (
                    <React.Fragment>
                        {locationLinkValue ? (
                            <SmartLink href={locationLinkValue} target="_blank" className="resort-location-link">
                                {locationLinkContent(options, link, resortLocation, myEnvLink, true)}
                            </SmartLink>
                        ) : (
                            locationLinkContent(options, link, resortLocation, myEnvLink, true)
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
    if (options.showAddressInformation && !options.showAddressInformationInline && content) {
        renderLocationData.push(renderAddress(content), <React.Fragment>{options.showPlanYourRouteButton && renderRouteUrl(content)}</React.Fragment>);
    }
    if (renderLocationData.length) {
        return (
            <div className={`${hideWidget}`}>
                {renderLocationData.map((locationData, index) => (
                    <React.Fragment key={index}>{locationData}</React.Fragment>
                ))}
            </div>
        );
    }
    return null;
};

function renderRouteUrl(content: Exclude<Content, Resort[]>) {
    const googleRouteUrl = "https://www.google.com/maps/dir//";
    const seperator = ", +";
    const routeUrl: string = googleRouteUrl + (content as Resort)?.address?.address1 + seperator + (content as Resort)?.address?.city + seperator + (content as Resort)?.address?.houseNumber;

    return (
        <SmartLink href={routeUrl} className={classNames("route-button")} target="blank">
            <span className={classNames("route-button--text")}>{getI18nLocaleString(namespaceList.widgetResultsLocation, "planYourRoute")}</span>
        </SmartLink>
    );
}

function resortLinkContent(options: WidgetOptions, link: ConfiguredLink, resortName: string, myEnvLink?: string, linkUrl?: string | null) {
    return linkUrl ? (
        <SmartLink
            href={linkUrl}
            target={link.target}
            className={`${options.resortNameColor?.includes("theme") ? `color-${options.resortNameColor}` : options.resortNameColor?.indexOf("rgba") === -1 ? options.resortNameColor : ""} ${
                options.resortNameFontSize || ""
            } location-item resortname`}
            style={{ color: options.styleResortName && options.resortNameColor?.includes("rgba") ? options.resortNameColor : undefined }}
        >
            {myEnvLink ? <CustomLink configuredLink={link} customLink={myEnvLink} linkText={resortName} /> : resortName}
        </SmartLink>
    ) : (
        <span
            // eslint-disable-next-line max-len
            className={`${options.resortNameColor?.includes("theme") ? `color-${options.resortNameColor}` : options.resortNameColor?.indexOf("rgba") === -1 ? options.resortNameColor : ""} ${
                options.resortNameFontSize || ""
            } location-item resortname`}
            style={{ color: options.styleResortName && options.resortNameColor?.includes("rgba") ? options.resortNameColor : undefined }}
        >
            {myEnvLink ? <CustomLink configuredLink={link} customLink={myEnvLink} linkText={resortName} /> : resortName}
        </span>
    );
}

function locationLinkContent(options: WidgetOptions, link: ConfiguredLink, resortLocation: string, myEnvLink: string | undefined, isPresent?: boolean, linkUrl?: string | null) {
    return linkUrl ? (
        <SmartLink href={linkUrl} target={link.target} className={`location-item city ${options.displayOnlyIcon ? "remove-text" : ""}`}>
            {options.showResortLocationIcon && (
                <FontAwesome
                    name="map-marker"
                    // eslint-disable-next-line max-len
                    className={`${
                        options.locationIconColor?.includes("theme") ? `color-${options.locationIconColor}` : options.locationIconColor?.indexOf("rgba") === -1 ? options.locationIconColor : ""
                    } ${options.resortLocationFontSize || ""}`}
                    style={{ color: options.locationIconColor?.includes("rgba") ? options.locationIconColor : undefined }}
                />
            )}
            <span className={isPresent ? `city-name ${options.resortLocationFontSize} ` : options.resortLocationFontSize}>
                {myEnvLink ? <CustomLink configuredLink={link} customLink={myEnvLink} linkText={resortLocation} /> : resortLocation}
            </span>
        </SmartLink>
    ) : (
        <span className={`location-item city ${options.displayOnlyIcon ? "remove-text" : ""}`}>
            {options.showResortLocationIcon && (
                <FontAwesome
                    name="map-marker"
                    // eslint-disable-next-line max-len
                    className={`${
                        options.locationIconColor?.includes("theme") ? `color-${options.locationIconColor}` : options.locationIconColor?.indexOf("rgba") === -1 ? options.locationIconColor : ""
                    } ${options.resortLocationFontSize || ""}`}
                    style={{ color: options.locationIconColor?.includes("rgba") ? options.locationIconColor : undefined }}
                />
            )}
            <span className={isPresent ? `city-name ${options.resortLocationFontSize} ` : options.resortLocationFontSize}>
                {myEnvLink ? <CustomLink configuredLink={link} customLink={myEnvLink} linkText={resortLocation} /> : resortLocation}
            </span>
        </span>
    );
}
/* jscpd:ignore-end */

function mapStateToProps(state: State): LocationWidgetStoreProps {
    return {
        myEnvState: state.myEnvState,
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
    };
}
export const Location = withDynamicField(wrapProps<LocationWidgetBaseProps>(connect<LocationWidgetStoreProps>(mapStateToProps)(LocationBase)));
