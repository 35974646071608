import * as React from "react";

import { ConfiguredLink, getUrlWithAnchor } from "../utils/linking.util";

import { Link } from "react-scroll";
import { SmartLink } from "./SmartLink";

export interface CustomLinkProps {
    customLink?: string;
    configuredLink: ConfiguredLink;
    linkText: string;
    linkClassName?: string;
    anchorClassName?: string;
    style?: React.CSSProperties;
}

export const CustomLink: React.FC<CustomLinkProps> = (props: CustomLinkProps): JSX.Element => {
    const { customLink, configuredLink, linkClassName, linkText, anchorClassName, style } = props;
    const url: string = customLink || getUrlWithAnchor(configuredLink);

    if (!configuredLink.url && configuredLink.anchor) {
        return (
            <Link className={anchorClassName} to={configuredLink.anchor} style={style} spy={true} smooth={true}>
                {linkText}
            </Link>
        );
    }

    return (
        <SmartLink className={linkClassName} href={url} target={configuredLink.target} rel={configuredLink.rel} style={style}>
            {linkText}
        </SmartLink>
    );
};
